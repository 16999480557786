@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    font-family: "IBM Plex Sans", system-ui;
}
.inputLoader {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
    }

    .loader {
        width: 28px;
        height: 28px;
        border: 2px solid #FF5A00;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 2s linear infinite;
        }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 




    .active {

        color: #00CCBC;
      }